import React, { useEffect, useState } from "react";
import axios from "../axios";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Space,
  Table,
  DatePicker,
  Tag,
  Flex,
  Row,
  Form,
  Select,
  Drawer,
  Col,
  Badge,
  Switch,
  notification,
  InputNumber,
  Typography
} from "antd";
import "./CustomTable.css";
import dayjs from "dayjs";
import TutorDrawer from "./TutorDrawer";
import { tutorsList } from "../states/tutors";
import { useAtom } from "jotai";
import { userRole, userTutorCityAccess } from "../states/userRole";
import { citysList } from "../states/citys";
import Autocomplete from "react-google-autocomplete";
import {
  academicLevels,
  courseCategories,
  teachingTypes,
} from "../constants/tutorConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { DATE_FILTER_TYPE, DATE_RANGE_MAP, getDateRange } from "./constants";
import { USER_ROLES } from "../constants/roleConstants";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const CustomTable = () => {
  const [searchText, setSearchText] = useState();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [searchFieldType, setSearchFieldType] = useState("first_name");
  const [filterForm] = Form.useForm();
  const [geoSearchData, setGeoSearchData] = useState(null);
  const [dateRangeFilter, setDateRangeFilter] = useState(null);
  const [filtersCount, setFiltersCount] = useState(0);
  const [selectedCourseCategory, setSelectedCourseCategory] = useState(null);
  const [streamsData, setStreamsData] = useState([]);
  const [selectedStream, setSelectedStream] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [citys, setCitys] = useAtom(citysList);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useAtom(tutorsList);
  const [open, setOpen] = useState(false);
  const [role, setRole] = useAtom(userRole);
  const [tutorCityAccess, setTutorCityAccess] = useAtom(userTutorCityAccess);
  const [activeRowId, setActiveRowId] = useState();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 200,
  });

  const [globalFilter, setGlobalFilter] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [dateRangeType, setDateRangeType] = useState("relative");
  const [dateRange, setDateRange] = useState([]);
  const [presetRange, setPresetRange] = useState("");
  const [limit, setLimit] = useState(0);

  const showDrawer = (activeRowId) => {
    setActiveRowId(activeRowId);
    setOpen(true);
  };

  useEffect(() => {
    applyFiltersFromURL();
  }, []);
  const updateURLWithFilters = (filters) => {
    const searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        if (Array.isArray(value)) {
          searchParams.append(`filter[${key}]`, value.join(","));
        } else {
          searchParams.append(`filter[${key}]`, value);
        }
      }
    });
    if (filters.page) searchParams.append("page", filters.page);
    if (filters.limit) searchParams.append("limit", filters.limit);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const applyFiltersFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    const filters = {};
    for (let [key, value] of searchParams.entries()) {
      if (key.startsWith('filter[')) {
        const matches = key.match(/filter\[(.*?)\]/);
        if (matches) {
          const [, filterKey] = matches;
          const splitValues = value.split(',');
          filters[filterKey] = Array.isArray(splitValues) && splitValues.length > 1 ? splitValues : value;
        }
      } else {
        filters[key] = value;
      }
    }

    filterForm.setFieldsValue(filters);

    if (filters.searchFieldType) {
      setSearchFieldType(filters.searchFieldType);
      setSearchText(filters.searchText);
      if (filters.searchFieldType === "address") {
        setGeoSearchData(filters.searchText);
      }
    }

    const filterCount = Object.keys(filters).filter(
      (key) => !["searchText", "searchFieldType", "page", "limit"].includes(key)
    ).length;
    setFiltersCount(filterCount);

    let fromDate = "",
      toDate = "";

    if (
      dateRangeType === DATE_FILTER_TYPE['relative'] &&
      presetRange &&
      DATE_RANGE_MAP[presetRange]
    ) {
      fromDate = DATE_RANGE_MAP[presetRange].fromDate;
      toDate = DATE_RANGE_MAP[presetRange].toDate;
    } else if (dateRangeType === DATE_FILTER_TYPE['absolute'] && dateRange.length === 2) {
      const [start, end] = dateRange;
      if (start.isAfter(end) || start.isSame(end)) {
        console.error("Start date cannot be same or after end date.");
        return;
      }
      fromDate = getDateRange(start);
      toDate = getDateRange(end)
    }

    if(fromDate){
      filters.fromDate = fromDate
    }
    if(toDate){
      filters.toDate = toDate
    }
    if (Object.keys(filters).length > 0) {
      fetchFilteredData(filters, filters.page, filters.limit);
    } else {
      fetchData(pagination.current, pagination.pageSize);
    }
  };
  useEffect(() => {
    applyFiltersFromURL();
  }, [location.search, dateRangeType, dateRange, presetRange, open]);

  const fetchData = async (page, pageSize) => {
    setLoading(true);
    try {
      const response = await axios.get("/v1/cre/tutor", {
        params: {
          page: page,
          limit: pageSize,
        },
      });
      if (
        response.data.is_success &&
        response.data.data &&
        response.data.data.Result
      ) {
        setData(response.data.data.Result);
        setPagination({
          ...pagination,
          current: page,
          pageSize: pageSize,
          total: response.data.data.total || 0,
        });
      } else {
        setData([]);
        setPagination({
          ...pagination,
          current: page,
          pageSize: pageSize,
          total: 0,
        });
      }
    } catch (error) {
      setData([]);
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
        total: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (current, pageSize, sorter) => {
    setPagination({
          ...pagination,
          current: current,
          pageSize: pageSize,
    });
    if (!globalFilter) {
      fetchData(current, pageSize);
    } else {
      fetchFilteredData(globalFilter,current, pageSize);
    }
  };

  const fetchFilteredData = async (filter, page, limit) => {
    setGlobalFilter(filter);
    setData([]);
    setLoading(true);

    const params = new URLSearchParams();
   //Todo: This is a temporary fix , it needed to be removed 
    if(filter.searchFieldType == "address" && Array.isArray(filter.searchText)){
      filter.searchText = `${filter.searchText[0] +","+ filter.searchText[1]}`
    }
    

    // Handle filter parameters
    Object.entries(filter).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        if (key === "searchText" || key === "searchFieldType") {
          // Directly append these specific filters without array notation
          params.append(`filter[${key}]`, value);
        } else {
          if (!Array.isArray(value)) {
            // Convert single values into an array
            value = [value];
          }
          
          // Append each value in the array with the correct format
          value.forEach((val, index) => {
            params.append(`filter[${key}][${index}]`, val);
          });
        }
      }
    });
    
    // Add pagination parameters
    params.append("page", page.toString());
    params.append("limit", limit.toString());

    updateURLWithFilters({ ...filter, page, limit });

    try {
      const response = await axios.get(
        `/v1/cre/tutor/filter?${params?.toString()}`
      );
      if (
        response.data.is_success &&
        response.data.data &&
        response.data.data.Result
      ) {
        setData(response.data.data.Result);
        setPagination({
          ...pagination,
          current: page,
          pageSize: limit,
          total: response.data.data.total || 0,
        });
      } else {
        setData([]);
        setPagination({
          ...pagination,
          current: page,
          pageSize: limit,
          total: 0,
        });
      }
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDateRangeTypeChange = (checked) => {
    setDateRangeType(checked ? DATE_FILTER_TYPE['absolute'] : DATE_FILTER_TYPE['relative']);
    setDateRange([]);
    setPresetRange("");
  };

  const fetchCoursesOfCategory = async (category_id) => {
    setStreamsData([]);
    axios
      .get("/v1/student/all-courses", { params: { category_id } })
      .then((response) => {
        setStreamsData(response.data);
      })
      .catch((error) => {});
  };

  const handleDownload = async () => {
    if (!limit) {
      notification.error({
        message: "Limit Required",
        description: "Please enter a limit value.",
      });
      return;
    }

    try {
      // Make API request to process bulk download
      const response = await axios.post('/v1/cre/download/', { limit, user_type:"Tutor" });

      if (response.data && response.data?.resp_code === 200) {
        notification.success({
          message: "Requested Download Data",
          description: "Requested Download Data Successfully",
        });
        setLimit(0);
      } else {
        notification.error({
          message: "Failed to Process",
          description: response.data.resp_message || "Unable to process bulk download.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occurred while processing bulk download.",
      });
    }
  };

  const columns = [
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => {
        if (a.created_at && b.created_at) {
          return new Date(a.created_at) - new Date(b.created_at);
        }
        return 0;
      },
      render: (_, record) => {
        let date = record?.created_at?.split("T")[0];
        let color = "white";
        if (record?.profile_status === "Block") {
          color = "red";
        }
        if (record?.profile_status === "InActive") {
          color = "cyan";
        }
        date = date ? dayjs(date).format("Do MMM YYYY") : "";

        return (
          <Tag
            color={color}
            style={{
              width: "100%",
            }}
          >
            <Flex
              style={{
                color: "#262121",
                margin: "0px auto",
                minHeight: "30px",
                minWidth: `${date?.length * 9}px`,
              }}
              justify="center"
              align="center"
            >
              {date}
            </Flex>
          </Tag>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (_, record) => {
        const name = record?.first_name + " " + record?.last_name;
        let color = "white";
        if (record?.profile_status === "Block") {
          color = "red";
        }
        if (record?.profile_status === "InActive") {
          color = "cyan";
        }

        return (
          <Tag
            color={color}
            style={{
              width: "100%",
            }}
          >
            <Flex
              align="center"
              style={{
                color: record?.is_paid ? "#59d559" : "#262121",
                fontWeight: record?.is_paid ? "bolder" : "",
                cursor: "pointer",
                textTransform: "capitalize",
                minHeight: "30px",
              }}
              onClick={() => showDrawer(record.id)}
            >
              <div style={{ minWidth: `${name?.length * 8}px` }}>
                {name}
                {record?.is_approved ? (
                  <img
                    src="./approved-icon.svg"
                    alt="tick mark"
                    height="12px"
                    style={{ marginLeft: "5px" }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Flex>
          </Tag>
        );
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Status",
      dataIndex: "tutor_status",
      key: "tutor_status",
      render: (_, record) => {
        const tutorStatusColorMapper = {
          New: "red",
          Doc_Done: "cyan",
          Interested: "orange",
          Not_Interested: "purple",
          Payment_Done: "green",
          Verified: "geekblue",
        };
        const status = record.tutor_status ? record.tutor_status : "New";
        return (
          <div style={{ minWidth: `${status?.length * 8}px` }}>
            <Tag color={tutorStatusColorMapper[status]}>{status}</Tag>
          </div>
        );
      },
    },
    {
      title: "Request Count",
      dataIndex: "email_request_count",
      key: "email_request_count",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
    },
    {
      title: "Teaching Type",
      dataIndex: "teching_type",
      key: "teching_type",
      render: (_, record) => {
        const courses = [
          ...new Set(record?.courses?.map((course) => course.course_name)),
        ].join(" | ");
        let teching_type = record.teching_type
          ? teachingTypes[record.teching_type]
          : "";
        if (courses.includes("Special")) teching_type += ",Special Educator";
        if (
          courses.includes("shadow teacher") ||
          courses.includes("Tuition support")
        )
          teching_type += ",Shadow Teacher";
        return (
          <div style={{ width: `${teching_type?.length * 8}px` }}>
            {teching_type}
          </div>
        );
      },
    },
    {
      title: "City",
      dataIndex: "city_id",
      key: "city_id",
      render: (_, record) => {
        const cityName = citys[record.city_id];
        return (
          <div
            style={{
              width: `${cityName?.length * 9}px`,
            }}
          >
            {cityName}
          </div>
        );
      },
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
      sorter: (a, b) => a.distance - b.distance,
      render: (_, record) => {
        let distance = record?.distance
          ? record.distance.toFixed(2) + " km"
          : "N/A";
        if (record?.distance === 0) distance = "0 Km";
        return <div>{distance}</div>;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_, record) => {
        const address = record?.address
          ?.split("<-->")
          .map((area) => {
            if (area != "undefined") return area;
            return "";
          })
          .join(" ");
        return (
          <div className="custometable-longcell-wrapper">
            <div
              className="custometable-longcell-container"
              style={{ width: `${address?.length * 8}px` }}
            >
              {address}
            </div>
          </div>
        );
      },
    },
    {
      title: "Courses",
      dataIndex: "courses",
      key: "courses",
      render: (_, record) => {
        const courses = [
          ...new Set(record?.courses?.map((course) => course.course_name)),
        ].join(" | ");
        return (
          <div className="custometable-longcell-wrapper">
            <div
              className="custometable-longcell-container"
              style={{ width: `${courses?.length * 8}px` }}
            >
              {courses}
            </div>
          </div>
        );
      },
    },
  ];

  const handleDateChange = (dates) => {
    if (dates) {
      setDateRange(dates);
      setPresetRange("");
    } else {
      setDateRange([]);
      setPresetRange("");
    }
  };
  const handlePresetRangeChange = (value) => {
    setPresetRange(value);
    setDateRange([]);
  };

  return (
    <>
      <Flex
        style={{ margin: "15px auto" }}
        align="center"
        justify="space-between"
        direction="row"
      >
        <Flex
          align="center"
          style={{ width: "450px", justifyContent: "flex-start" }}
        >
          <div style={{ width: "120px", marginRight: "10px" }}>
            <Switch
              checkedChildren="Absolute"
              unCheckedChildren="Relative"
              checked={dateRangeType === "absolute"}
              onChange={handleDateRangeTypeChange}
            />
          </div>
          {dateRangeType === "absolute" ? (
            <RangePicker
              style={{ width: "300px" }}
              value={dateRange}
              onChange={handleDateChange}
              allowClear={false}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
            />
          ) : (
            <Select
              style={{ width: "300px" }}
              value={presetRange}
              onChange={handlePresetRangeChange}
            >
              <Option value="today">Today</Option>
              <Option value="yesterday">Yesterday</Option>
              <Option value="this_week">This Week</Option>
              <Option value="last_week">Last Week</Option>
              <Option value="this_month">This Month</Option>
              <Option value="last_month">Last Month</Option>
            </Select>
          )}
        </Flex>

        <Row align="middle">
          <Col style={{ margin: "auto 10px" }}>
            <Select
              placeholder="Select Type"
              style={{ width: 120, fontWeight: "bolder" }}
              value={searchFieldType}
              onChange={(e) => {
                setSearchFieldType(e);
                setSearchText(null);
              }}
              defaultValue={"name"}
            >
              <Option value="first_name">Name</Option>
              <Option value="phone">Phone</Option>
              <Option value="address">Address</Option>
              <Option value="userId">User Id</Option>
            </Select>
          </Col>

          {searchFieldType === "address" ? (
            <Flex style={{ margin: "auto 10px" }}>
              <Autocomplete
                apiKey={apiKey}
                style={{ width: "500px", paddingRight: "30px" }}
                className="ant-input css-dev-only-do-not-override-1uweeqc ant-input-outlined"
                onPlaceSelected={(place) => {
                  setSearchText(place.formatted_address);
                  let latitude = place.geometry.location.lat();
                  let longitude = place.geometry.location.lng();
                  setGeoSearchData(latitude + "," + longitude);
                }}
                options={{
                  types: [],
                  componentRestrictions: { country: "IN" },
                }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />

              <SearchOutlined
                style={{
                  cursor: "pointer",
                  marginLeft: "-29px",
                  padding: "5px",
                  zIndex: 1,
                  color: "rgba(0, 0, 0, 0.5)",
                  borderLeft: "1px solid #d9d9d9",
                }}
                onClick={() => {
                  if (searchText){
                    const {current, pageSize} = pagination;
                    fetchFilteredData({
                      ...(globalFilter ? globalFilter : {}),
                      searchText: geoSearchData,
                      searchFieldType,
                    }, current, pageSize);
                  }
                    
                }}
              />
            </Flex>
          ) : (
            <Search
              value={searchText}
              type={searchFieldType === "phone" ? "number" : "text"}
              maxLength={searchFieldType === "phone" ? 10 : undefined}
              style={{ width: 500 }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              placeholder={searchFieldType === "phone" ? "Phone" : "Search"}
              onSearch={() => {
                if (searchText){
                  const {current, pageSize} = pagination;
                  fetchFilteredData({
                    ...(globalFilter ? globalFilter : {}),
                    searchText,
                    searchFieldType,
                  }, current, pageSize);
                }
                  
              }}
            />
          )}
        </Row>

        {searchFieldType !== "userId" && (
          <Col style={{ margin: "auto 10px", position: "relative" }}>
            <Badge
              count={filtersCount}
              style={{
                position: "absolute",
                top: "0px",
                cursor: "pointer",
                right: "8px",
                backgroundColor: "rgba(39, 65, 120, 1)",
              }}
              onClick={() => {
                setFilterDrawerOpen(true);
              }}
              overflowCount={99}
            >
              <FilterOutlined
                style={{ cursor: "pointer", margin: "auto 12px" }}
              />
            </Badge>
          </Col>
        )}

        <Col>
          <Button
            type="primary"
            style={{
              margin: "10px",
              color: "white",
              backgroundColor: "rgba(39, 65, 120, 1)",
            }}
            onClick={() => {
              setSearchText(null);
              setSearchFieldType("first_name");
              setGeoSearchData(null);
              fetchData(1, 10);
              setGlobalFilter(null);
              setFiltersCount(0);
              filterForm.resetFields();
            }}
          >
            Clear Filters
          </Button>
        </Col>
      </Flex>

      {
        role === USER_ROLES.SUPERADMIN && (
          <Flex justify="flex-end">
            <Row>
              <Col>
                <InputNumber
                    min={1}
                    max={24}
                    placeholder="Enter Limit"
                    value={limit}
                    onChange={(value) => setLimit(value)}  // No need to access `e.value`, just use `value`
                    style={{ margin: '10px', width: '150px' }}
                  />
                  <Typography.Text>Limit (in Months)</Typography.Text>
              </Col>
              <Col>
                  <Button
                    type="primary"
                    style={{
                      margin: '10px',
                      color: 'white',
                      backgroundColor: 'rgba(39, 65, 120, 1)',
                    }}
                    onClick={handleDownload}
                  >
                    Download
                  </Button>
              </Col>
            </Row>
          </Flex>
        )
      }

      <Flex style={{ overflow: "scroll", marginLeft: "5px" }}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            ...pagination,
            onChange: handleTableChange,
            showSizeChanger: true,
            pageSizeOptions: [10, 15, 20, 25, 50, 100, 150, 200, 250, 500],
          }}
          loading={loading}
          onChange={(pagination, filters, sorters) => {
            if (!globalFilter) {
              fetchData(pagination.current, pagination.pageSize);
            } else {
              fetchFilteredData(
                globalFilter,
                pagination.current,
                pagination.pageSize
              );
            }
          }}
        />
      </Flex>

      {open && <TutorDrawer id={activeRowId} open={open} setOpen={setOpen} />}

      <Drawer
        open={filterDrawerOpen}
        title="Filter"
        width={600}
        placement="right"
        closable
        onClose={() => setFilterDrawerOpen(false)}
      >
        <Form
          layout="vertical"
          form={filterForm}
          style={{ padding: "0px" }}
          onFinish={(e) => {
            const { current, pageSize } = pagination;
            fetchFilteredData(
              {
                ...e,
                date_range: e.date_range
                  ? dateRangeFilter?.created_at
                  : undefined,
                searchFieldType,
                searchText:
                  searchFieldType === "address" ? geoSearchData : searchText,
              },
              current,
              pageSize
            );
            setFilterDrawerOpen(false);
          }}
        >
          <Form.Item label="Course Category" name={"course_category"}>
            <Select
              style={{ width: "100%" }}
              allowClear
              onClear={() => {
                setSelectedCourseCategory(null);
                setSelectedCourses([]);
                setSelectedStream(null);
                setStreamsData([]);

                filterForm.setFieldsValue({
                  course: [],
                  course_stream: null,
                  course_level: null,
                });
              }}
              placeholder="Select Course Category"
              onChange={(value) => {
                filterForm.setFieldsValue({
                  course: [],
                  course_stream: null,
                  course_level: null,
                });
                setSelectedCourseCategory(value);
                (async () => {
                  setSelectedCourses([]);
                  setSelectedStream(null);
                  setStreamsData([]);

                  if (value == 1) {
                    return;
                  }
                  await fetchCoursesOfCategory(value);
                })();
              }}
            >
              {Object.keys(courseCategories).map((Category_id) => (
                <Option key={Category_id} value={Category_id}>
                  {courseCategories[Category_id]}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {selectedCourseCategory == 1 && (
            <Form.Item label="Level" name={"course_level"}>
              <Select
                style={{ width: "100%" }}
                placeholder="Select Level"
                onChange={(value) => {
                  setStreamsData([]);
                  setSelectedStream(null);
                  setSelectedCourses([]);

                  filterForm.setFieldsValue({
                    course: [],
                    course_stream: null,
                  });
                  (async () => {
                    fetchCoursesOfCategory(
                      "11th Class, 12th Class".includes(value)
                        ? 4
                        : selectedCourseCategory
                    );
                  })();
                }}
              >
                {academicLevels.map((level) => (
                  <Option key={level} value={level}>
                    {level}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {streamsData?.length > 0 && (
            <Form.Item label="Course Stream" name={"course_stream"}>
              <Select
                style={{ width: "100%" }}
                placeholder="Select Course Stream"
                onChange={(value) => {
                  filterForm.setFieldsValue({ course: [] });
                  setSelectedCourses([]);
                  setSelectedStream(value);
                }}
              >
                {streamsData.map((stream) => (
                  <Option key={stream.stream_id} value={stream.stream_id}>
                    {stream.stream_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {selectedStream && (
            <Form.Item label="Course" name={"course"} value={selectedCourses}>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select Course"
                onChange={(value) => {
                  setSelectedCourses(value);
                }}
              >
                {streamsData
                  .find((stream) => stream.stream_id == selectedStream)
                  .courses.map((course) => (
                    <Option key={course} value={course}>
                      {course}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item label="Gender" name="gender">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select Gender"
            >
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Status" name={"tutor_status"}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select Tutor Status"
            >
              <Option value={"New"}>New</Option>
              <Option value={"Interested"}>Interested</Option>
              <Option value={"Doc_Done"}>Doc Done</Option>
              <Option value={"Not_Interested"}>Not Interested</Option>
              <Option value={"Payment_Done"}>Payment Done</Option>
              <Option value={"Verified"}>Verified</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Teaching Type" name={"teaching_type"}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select Teaching Type"
            >
              <Option value="Looking for Part Time Tuitions">Tutor</Option>
              <Option value="Looking for Full time Teaching Job">
                Teacher
              </Option>
              <Option value="Both">Both</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Profile Status" name={"profile_status"}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select Profile Status"
            >
              <Option value={"Active"}>Active</Option>
              <Option value={"InActive"}>In Active</Option>
              <Option value={"Block"}>Block</Option>
            </Select>
          </Form.Item>

          <Form.Item label="City" name={"city"}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select City"
            >
              {role != "Superadmin"
                ? tutorCityAccess?.map((city_id) => (
                    <Option key={city_id} value={city_id}>
                      {citys[city_id]}
                    </Option>
                  ))
                : Object.keys(citys).map((city_id) => (
                    <Option key={city_id} value={city_id}>
                      {citys[city_id]}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
          <Form.Item label="Experience in Years" name={"experience"}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select Experience in Years"
            >
              <Option value="Fresher">Fresher</Option>
              <Option value="1 year">1 year</Option>
              <Option value="2 years">2 years</Option>
              <Option value="3 years">3 years</Option>
              <Option value="4 years">4 years</Option>
              <Option value="5 years">5 years</Option>
              <Option value="6 years">6 years</Option>
              <Option value="7 years">7 years</Option>
              <Option value="8 years">8 years</Option>
              <Option value="9 years">9 years</Option>
              <Option value="10 years">10 years</Option>
              <Option value="11 years">11 years</Option>
              <Option value="12 years">12 years</Option>
              <Option value="13 years">13 years</Option>
              <Option value="14 years">14 years</Option>
              <Option value="15 years">15 years</Option>
              <Option value="16 years">16 years</Option>
              <Option value="17 years">17 years</Option>
              <Option value="18 years">18 years</Option>
              <Option value="19 years">19 years</Option>
              <Option value="20 years">20 years</Option>
              <Option value="21 years">21 years</Option>
              <Option value="22 years">22 years</Option>
              <Option value="23 years">23 years</Option>
              <Option value="24 years">24 years</Option>
              <Option value="25 years">25 years</Option>
              <Option value="26 years">26 years</Option>
              <Option value="27 years">27 years</Option>
              <Option value="28 years">28 years</Option>
              <Option value="29 years">29 years</Option>
              <Option value="30 years">30 years</Option>
              <Option value=">30 years">Above 30 years</Option>
            </Select>
          </Form.Item>

          <Space style={{ margin: "10px" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "5px" }}
            >
              Submit
            </Button>
            <Button
              type="default"
              onClick={() => {
                filterForm.resetFields();
                setFilterDrawerOpen(false);
              }}
            >
              Cancel
            </Button>
          </Space>
        </Form>
      </Drawer>
    </>
  );
};

export default CustomTable;
