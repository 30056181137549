import React, { useEffect, useState } from "react";
import { Flex, Table, Tag, message } from "antd";
import axios from "../axios";
import dayjs from "dayjs";

const DownloadsTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  // Fetch data function
  const fetchData = async (page = pagination.current, limit = pagination.pageSize) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `v1/cre/get-bulk-data?page=${page}&limit=${limit}`
      );

      if (response && response?.data?.is_success) {
        const { Result, total } = response.data?.data;
        setData(Result);
        setPagination((prev) => ({ ...prev, total })); // Update only total
      } else {
        message.error(response.data.resp_message || "Failed to fetch data.");
      }
    } catch (error) {
      message.error("Error fetching data.");
    }
    setLoading(false);
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Handle table change (pagination)
  const handleTableChange = (currentPage) => {
    const newPagination = { ...pagination, current:currentPage }; // Create a new pagination object
    setPagination(newPagination); // Update pagination state
    fetchData(newPagination.current, newPagination.pageSize); // Fetch data
  };

  // Columns definition
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
    },
    {
      title: "Limit",
      dataIndex: "limit",
      key: "limit",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => {
        let date = record?.created_at?.split("T")[0];
        date = date ? dayjs(date).format("Do MMM YYYY") : "";

        return (
          <Tag
            style={{
              width: "100%",
            }}
          >
            <Flex
              style={{
                color: "#262121",
                margin: "0px auto",
                minHeight: "30px",
                minWidth: `${date?.length * 9}px`,
              }}
              justify="center"
              align="center"
            >
              {date}
            </Flex>
          </Tag>
        );
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (_, record) => {
        let date = record?.updated_at?.split("T")[0];
        date = date ? dayjs(date).format("Do MMM YYYY") : "";

        return (
          <Tag
            style={{
              width: "100%",
            }}
          >
            <Flex
              style={{
                margin: "0px auto",
                minHeight: "30px",
                minWidth: `${date?.length * 9}px`,
              }}
              justify="center"
              align="center"
            >
              {date}
            </Flex>
          </Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "is_completed",
      key: "is_completed",
      render: (isCompleted) =>
        isCompleted ? (
          <Tag style={{ color: "green" }}>Processed</Tag>
        ) : (
          <Tag style={{ color: "orange" }}>Processing</Tag>
        ),
    },
    {
      title: "File Link",
      dataIndex: "file_link",
      key: "file_link",
      render: (file_link) =>
        file_link ? (
          <a href={file_link} target="_blank" rel="noopener noreferrer">
            Download
          </a>
        ) : (
          "N/A"
        ),
    },
  ];

  return (
    <Flex style={{ width: "98%", margin: "0 auto" }}>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{
          ...pagination,
          onChange: handleTableChange,
          showSizeChanger: true,
          pageSizeOptions: [10, 15, 20, 25, 50, 100, 150, 200, 250, 500],
        }}
        loading={loading}
      />
    </Flex>
  );
};

export default DownloadsTable;
